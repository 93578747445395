body {
  margin: 0;
  padding: 0;
  color: black;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  text-align: center;
}

.button-container {
  margin-top: 0px;
  margin-bottom: 8px;
}

.draft-pool-container {
  margin-top: 20px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .App {
    background-image: url('./assets/img/mobile.jpg');
  }
}

@media (min-width: 1025px) {
  .App {
    background-image: url('./assets/img/desktop.jpg');
  }
}

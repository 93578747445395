:root {
  --primary-color: #007bff;
  --text-color: #333;
  --background-color: #f4f4f4;
  --line-height: 1.6;
  --spacing-1: 1rem;
  --spacing-2: 2rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  line-height: var(--line-height);
  background-color: var(--background-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: var(--spacing-1);
  font-weight: 600;
}

p {
  margin-bottom: var(--spacing-1);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-1);
}

.mt-1,
.mb-1,
.pt-1,
.pb-1 {
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-1);
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.mt-2,
.mb-2,
.pt-2,
.pb-2 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}
